export default () => {
    let isFetchedById = false;

    const getRecommendedSpeakerById = (id) => {
        const { findOne } = useStrapi4();
        const nuxtData = useNuxtData(`/products/mix/recommended-speaker/${id}`);

        if (!isFetchedById) {
            isFetchedById = true;

            useLazyAsyncData(
                `/products/mix/recommended-speaker/${id}`,
                () => findOne('speakers', id, {
                    populate: {
                        block_author_image: true,
                    },
                    fields: [
                        'id',
                        'name',
                        'sb_slug',
                    ],
                }),
                {
                    transform: (res) => {
                        const { data } = res;

                        return {
                            id: data?.id,
                            name: data?.attributes?.name,
                            sb_slug: data?.attributes?.sb_slug,
                            ...(data?.attributes?.block_author_image && {
                                image: {
                                    id: data?.attributes?.block_author_image?.data?.id,
                                    ...data?.attributes?.block_author_image?.data?.attributes,
                                },
                            }),
                        };
                    },
                },
            );
        }

        return ref(nuxtData.data);
    };

    let isFetchedByUid = false;

    const getRecommendedSpeakerByUuid = (uuid) => {
        const { find } = useStrapi4();
        const nuxtData = useNuxtData(`/products/mix/recommended-speaker/${uuid}`);

        if (!isFetchedByUid) {
            isFetchedByUid = true;

            useLazyAsyncData(
                `/products/mix/recommended-speaker/${uuid}`,
                () => find('speakers', {
                    filters: {
                        sb_uuid: {
                            $eq: uuid,
                        },
                    },
                    populate: {
                        block_author_image: true,
                    },
                    fields: [
                        'id',
                        'name',
                        'sb_slug',
                    ],
                }),
                {
                    transform: (res) => {
                        const { data } = res;
                        const speaker = data?.[0];

                        return {
                            id: speaker?.id,
                            name: speaker?.attributes?.name,
                            sb_slug: speaker?.attributes?.sb_slug,
                            ...(speaker?.attributes?.block_author_image && {
                                image: {
                                    id: speaker?.attributes?.block_author_image?.data?.id,
                                    ...speaker?.attributes?.block_author_image?.data?.attributes,
                                },
                            }),
                        };
                    },
                },
            );
        }

        return ref(nuxtData.data);
    };

    return {
        getRecommendedSpeakerById,
        getRecommendedSpeakerByUuid,
    };
};
